 /* button input header */
 
 * {
     margin: 0;
     padding: 0;
     font-size: 16px;
     font-family: 'Poppins', sans-serif;
     font-weight: 500;
 }
 .body-component{
     margin-bottom: 55px;
 }
 .main-root {
     width: 100%;
     margin: 0 auto;
     min-height: 100vh;
     position: relative;
 }
 
 .main-left-side {
     display: flex;
     justify-content: center;
     height: 100%;
     background: #FF7538;
     align-items: center;
 }
 
 .main-left-side img {
     width: 50%;
     margin: 0 auto;
 }
 /* start input type */
 
 .input-basic {
     width: 100%;
     border: none;
     border-left: 8px solid #FF7538;
     border-radius: 0px;
     margin-bottom: 2rem;
     padding: 1rem;
     box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
 }
 
 .input-basic::placeholder {
     color: black;
     font-weight: 600;
 }
 
 .input-basic:focus-visible {
     outline: none;
 }
 /* end input type */
 
 .submit-single-btn {
     background-color: transparent;
     border: none;
     color: black;
     font-weight: bolder;
     font-size: 17px;
 }
 
 .submit-single-btn i {
     color: #FF7538;
     font-size: 22px;
     margin-left: 2px;
 }
 
 .Heading-for-modal,
 .Heading-for-modal>p,
 .Heading-for-modal h1 {
     font-size: 16px;
     color: black;
     font-weight: bolder;
 }
 
 .setHeading {
     text-align: center;
     margin: 30px 0px;
 }
 
 .setHeading>h2 {
     color: #FF7538;
     font-weight: bold;
     font-size: 16px;
 }
 /* start single buttons submit center */
 
 .singleButtonDiv {
     display: flex;
     justify-content: space-around;
 }
 /* end single buttons submit center */
 
 .color-class {
     cursor: pointer;
     color: #FF7538;
     text-decoration: none;
     font-weight: bold;
 }
 
 .color-class:hover {
     color: #FF7538;
     text-decoration: none;
 }
 
 .colorClass2 {
     text-decoration: none;
     font-weight: 500;
 }
 
 .color-class-black {
     color: black;
     text-decoration: none;
 }
 /* start two buttons submit and close */
 
 .submitAndCloseBtn {
     display: flex;
     width: 100%;
     justify-content: space-between;
 }
 
 .submitBtn {
     background-color: #FF7538;
     color: white;
     text-decoration: none;
     padding: 12px 40px;
     border: none;
     font-weight: bold;
 }
 
 .submitBtn:hover {
     color: white;
 }
 
 .closeBtn {
     border: 1px solid #333;
     color: black;
     text-decoration: none;
     padding: 12px 40px;
     font-weight: bold;
 }
 
 .closeBtn {
     background: white;
 }
 
 .closeBtn:hover {
     color: black;
 }
 /* end two buttons submit and close */
 /* cancel and save button start */
 
 .cancel-and-save-button {
     display: flex;
     width: 100%;
     justify-content: space-between;
     padding-bottom: 60px;
 }
 
 .saveBtn {
     background-color: #FF7538;
     border-radius: 4px;
     color: white;
     text-decoration: none;
     padding: 12px 40px;
     border: none;
     font-weight: bold;
     /* Firefox */
     -moz-transition: all .15s ease;
     /* WebKit */
     -webkit-transition: all .15s ease;
     /* Opera */
     -o-transition: all .15s ease;
     /* Standard */
     transition: all .15s ease;
 }
 
 .saveBtn:hover {
     background-color: #ef6e34;
 }
 /* cancel and save button end */
 
 .save-cancel-exit-btn {
     margin-top: 20px;
     display: flex;
     justify-content: space-between;
 }
 
 .right-wrapper {
     display: flex;
     width: 100%;
     min-height: 100vh;
     align-items: center;
 }
 
 .right-wrapper-content {
     width: 100%;
 }
 
 .logo {
     width: 50%;
 }
 /*start modal*/
 
 .modal-footer {
     border-top: none !important;
 }
 
 .modal-input {
     margin-bottom: 0px;
 }
 
 .modal-header {
     flex-direction: column-reverse;
 }
 
 .modal.show .modal-dialog {
     width: 100%;
 }
 
 .modal {
     display: flex !important;
     justify-content: center;
     align-items: center;
 }
 
 .logoutBtn {
     background: transparent;
     border: 1px solid black;
     font-weight: bold;
     padding: 4px 6px;
 }
 /* end modal */
 /* start loading spinner */
 
 .spinner-component {
     position: absolute;
     width: 100%;
     height: 100%;
     top: 0;
     left: 0;
     z-index: 9999;
 }
 
 .spinner {
     justify-content: center;
     align-items: center;
     display: flex;
     width: 100%;
     height: 80%;
 }
 
 .spinner div {
     transform-origin: 40px 40px;
     animation: spinner 1.2s linear infinite;
 }
 
 .spinner div:after {
     content: " ";
     display: block;
     position: absolute;
     top: 3px;
     left: 37px;
     width: 6px;
     height: 18px;
     border-radius: 20%;
     background: red;
 }
 
 .spinner div:nth-child(1) {
     transform: rotate(0deg);
     animation-delay: -1.1s;
 }
 
 .spinner div:nth-child(2) {
     transform: rotate(30deg);
     animation-delay: -1s;
 }
 
 .spinner div:nth-child(3) {
     transform: rotate(60deg);
     animation-delay: -0.9s;
 }
 
 .spinner div:nth-child(4) {
     transform: rotate(90deg);
     animation-delay: -0.8s;
 }
 
 .spinner div:nth-child(5) {
     transform: rotate(120deg);
     animation-delay: -0.7s;
 }
 
 .spinner div:nth-child(6) {
     transform: rotate(150deg);
     animation-delay: -0.6s;
 }
 
 .spinner div:nth-child(7) {
     transform: rotate(180deg);
     animation-delay: -0.5s;
 }
 
 .spinner div:nth-child(8) {
     transform: rotate(210deg);
     animation-delay: -0.4s;
 }
 
 .spinner div:nth-child(9) {
     transform: rotate(240deg);
     animation-delay: -0.3s;
 }
 
 .spinner div:nth-child(10) {
     transform: rotate(270deg);
     animation-delay: -0.2s;
 }
 
 .spinner div:nth-child(11) {
     transform: rotate(300deg);
     animation-delay: -0.1s;
 }
 
 .spinner div:nth-child(12) {
     transform: rotate(330deg);
     animation-delay: 0s;
 }
 
 @keyframes spinner {
     0% {
         opacity: 1;
     }
     100% {
         opacity: 0;
     }
 }
 /* end loading spinner */
 /*start add user */
 
 .form-inputs-div {
     box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.2)!important;
     width: 100%;
     margin: 0 auto;
     padding-top: 14px;
 }
 
 .form-inputs-div label {
     font-weight: 600;
 }
 
 .form-inputs-div h5 {
     color: #FF7538;
     margin-bottom: 16px;
     font-weight: bold;
     font-size: 16px;
 }
 
 .add-user-root {
     height: 81vh;
 }
 
 .add-user-form-root {
     margin-left: 5px;
 }
 /* .add-update-right-wrapper::-webkit-scrollbar {
     width: 6px;
 }
 
 .add-update-right-wrapper::-webkit-scrollbar-track {
     box-shadow: inset 0 0 10px #c1c1c1;
 }
 
 .add-update-right-wrapper::-webkit-scrollbar-thumb {
     box-shadow: inset 0 0 10px #c1c1c1;
 }
 
 .add-update-right-wrapper:hover::-webkit-scrollbar-thumb {
     box-shadow: inset 0 0 10px #c1c1c1;
 } */
 
 @media screen and (max-height: 783px) {
     /* styles */
     .add-update-right-wrapper {
         padding-right: 5px;
     }
     .add-user-form-root {
         padding-right: 5px;
     }
 }
 /*end add user */
 
 @media screen and (max-width: 992px) {
     /* styles */
     .main-left-side {
         display: none;
     }
 }
 
 @media screen and (max-width: 992px) {
     /* styles */
     .main-left-side {
         display: none;
     }
 }
 
 .invalid {
     border: 1px solid red ! important;
     border-radius: 4px;
 }
 
 .ag-cell.ag-cell-auto-height.ag-cell-focus.ag-cell-inline-editing:active,
 .ag-cell.ag-cell-auto-height.ag-cell-focus.ag-cell-inline-editing:focus,
 .ag-cell.ag-cell-auto-height.ag-cell-focus.ag-cell-inline-editing:focus-within,
 .ag-cell.ag-cell-auto-height.ag-cell-focus.ag-cell-inline-editing:target,
 .ag-cell.ag-cell-auto-height.ag-cell-focus.ag-cell-inline-editing:visited,
 .ag-cell.ag-cell-auto-height.ag-cell-focus.ag-cell-inline-editing:focus-visible {
     height: inherit;
 }
 
 .ag-theme-alpine .ag-cell.ag-cell-first-right-pinned {
     border-left: none!important;
 }
 
 .ag-theme-alpine .ag-cell-focus {
     border: none!important
 }
 
 .ag-theme-alpine .ag-root-wrapper {
     border: none!important;
     background-color: #fff;
 }
 
 .ag-theme-alpine .ag-header {
     background-color: #fff!important;
 }
 
 .ag-theme-alpine .ag-row-odd {
     background-color: #fff!important;
 }
 
 .ag-theme-alpine .ag-header-cell-resize::after {
     content: none!important;
 }
 
 .application-grid {
     padding-top: 50px;
 }
 
 .ag-custom-header,
 .ag-theme-alpine .ag-cell {
     padding-left: 0!important;
     padding-right: 0!important;
 }
 
 .ag-pinned-right-header .ag-header-cell-text,
 .ag-pinned-right-cols-container ul.trigger-div {
     padding-left: 10px;
 }
 
 .pagination {
     float: right;
 }
 
 .ag-header.ag-focus-managed.ag-pivot-off {
     min-height: 30px !important;
 }
 
 .ag-pinned-right-cols-container,
 .ag-pinned-right-header {
     /* -webkit-box-shadow: 0 0 6px rgb(40 47 57 / 15%); */
     box-shadow: 0 0 6px rgb(40 47 57 / 15%);
 }
 
 .pagination-component .pagination {
     text-align: center !important;
 }
 /* start pagination*/
 
 .pagination {
     text-align: right;
     display: block;
 }
 
 .pagination li {
     display: inline-block;
 }
 
 .pagination>.active>a {
     color: #fff !important;
     background: #FF7538;
     cursor: not-allowed !important;
     border-color: #FF7538;
 }
 
 .pagination>.active {
     cursor: not-allowed;
 }
 
 .pagination>li>a,
 .pagination>li>span {
     position: relative;
     float: left;
     padding: 6px 12px;
     margin-left: 4px !important;
     line-height: 1.42857143;
     color: #8c8b8b;
     text-decoration: none;
     background-color: #fff;
     border: 1px solid #ddd;
     border-radius: 4px !important;
     /* color: #fff !important; */
 }
 /*end pagination*/
 /* .page_heading_wrap div[class^=col-]+div[class^=col-] {
     margin-top: 5px;
 } */
 
 .grid-row-count .SelectBox {
     width: 70px;
 }
 
 .grid-row-count .css-tlfecz-indicatorContainer,
 .grid-row-count .css-1gtu0rj-indicatorContainer {
     padding: 2px;
 }
 
 .grid-row-count,
 .grid-instructor {
     display: inline-flex;
 }
 
 .grid-instructor .SelectBox {
     width: 200px
 }
 
 span.label-content {
     padding: 7px 10px;
     font-weight: 700;
 }

 .category{
    min-width: max-content;
 }
 
 .search_wrap {
     width: 100%;
 }
 
 .form-group label {
     color: #2D2F39;
     opacity: 1;
 }
 
 span.input-group-addon {
     padding: 7px 10px;
     background: #FF7538 0% 0% no-repeat padding-box;
     box-shadow: 0px 0px 6px #282f3926;
     border-radius: 0px 5px 5px 0px;
     opacity: 1;
 }
 
 span.input-group-addon i {
     color: #fff;
     font-size: 15px;
 }
 
 .input-group #search {
     background: #FFFFFF 0% 0% no-repeat padding-box;
     box-shadow: 0px 0px 6px #282f3926;
     opacity: 1;
     border: none;
 }
 
 .profilePic {
     border: 1px solid #f05929 !important;
 }
 
 .avtarPhoto {
     position: relative;
 }
 
 .avtarPhoto input {
     display: none;
 }
 
 i.editavtar {
     position: absolute;
     bottom: 10%;
     left: calc(50% + 26px);
     border: 1px solid #f05929;
     color: #f05929;
     padding: 5px;
     font-size: 10px;
     border-radius: 50%;
     background: #fff;
 }
 
 button.submitSaveBtn:disabled,
 button.submitSaveBtn[disabled] {
     border: 1px solid #cccccc;
     background-color: #cccccc;
     color: #fff;
 }
 
 .ag-pinned-right-header {
     border-left: none!important;
 }
 
 .pointer {
     cursor: pointer;
 }
 
 .sensorAddDiv {
     background: #FFFFFF 0% 0% no-repeat padding-box;
     box-shadow: 0px 12px 40px #00000014;
     border: 0.20000000298023224px solid #cccccc;
     opacity: 1;
     padding: 20px;
     max-height: 430px;
     min-height: 275px;
     overflow: auto;
 }
 
 .sensor-ui {
     display: grid;
     grid-template-columns: 10fr 1fr;
 }
 
 .question-set-ui {
     display: grid;
     grid-template-columns: 8fr 4fr;
 }
 
 .sensorAddDiv .SelectBox:focus,
 .organge-border select,
 .organge-border input {
     border: 1px solid #FF7538!important;
 }
 .organge-border-forQuestionaire__control{
    border: 1px solid #FF7538 !important;
    border-radius: 0.25rem !important;
 }
 .organge-border-forQuestionaire__input input{
     opacity: 1 !important;
 }
 
 .sensorAddDiv .css-1pahdxg-control:hover,
 .sensorAddDiv .css-1pahdxg-control:focus,
 .sensorAddDiv .css-1pahdxg-control {
     border: 1px solid #FF7538!important;
     border-radius: 0.25rem;
     box-shadow: none!important;
 }
 
 .css-1n7v3ny-option,
 .css-1n7v3ny-option:active,
 .css-1n7v3ny-option:hover {
     background-color: unset!important;
 }
 
 .red-border input:focus,
 .red-border input {
     border: 1px solid red!important;
 }
 
 .green-border input:focus,
 .green-border input {
     border: 1px solid green!important;
 }
 
 .bottom-restore {
     position: absolute;
     font-size: 11px;
     font-weight: 600;
     margin-left: -6px;
     margin-top: 4px;
 }
 
 .grid-list-div {
     box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%) !important;
     border: 1px solid #ddd8d8 !important;
 }
 
 .minValBottomLine {
     border-bottom: 1px solid orange;
 }
 
 .supportPillsRight {
     padding: 20px 20px;
     box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%) !important;
     min-height: 100%;
 }
 
 .supportPills>.nav-item>a.active {
     background-color: #f97538 !important;
 }
 
 .supportPills>.nav-item>a {
     padding: 10px 20px;
     box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%) !important;
     margin: 6px 0px;
     color: black;
     border: 1px solid #bbb;
 }
 
 .manual-heading {
     font-weight: 600;
     color: #f97538;
 }
 
 .manual {
     padding: 20px 10px 20px 20px;
     margin: 10px 0px;
     box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075)!important;
     border: 1px solid #bbb;
 }
 
 .manual:hover {
     box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%) !important;
 }
 
 .manual-data {
     /* justify-content: space-around; */
     max-height: calc(100vh - 324px);
     overflow-y: auto;
 }
 
 .reference-links {
     padding-left: 20px;
 }
 
 .manualIcon {
     width: 45px;
     height: 45px;
     border-radius: 50%;
     background-color: #f97538;
     display: flex;
     justify-content: space-around;
     align-items: center;
 }
 
 .manualLeftTabName {
     display: flex;
 }
 
 .manualIcon img {
     max-height: 27px;
 }
 
 .manual i {
     font-size: 32px;
     color: white;
 }
 
 .manualName {
     display: flex;
     align-items: center;
     text-align: left;
     word-break: break-word;
     padding-left: 10px;
 }
 
 .reference-links li {
     padding: 10px 0px;
 }
 
 .reference-links li a {
     padding: 0px 10px;
 }
 
 .reference-links>a:hover {
     /* color: #f97538; */
     color: black;
 }
 
 .minValue {
     min-width: 30px;
     margin: 0px;
     padding: 0px;
     line-height: normal;
     border-bottom: 1px solid #f97538;
     text-align: center;
 }
 
 .supportPills {
     flex-wrap: nowrap;
 }
 
 .supportPills h6 {
     font-weight: 600;
 }
 
 .support-contact-form label {
     font-weight: 600;
 }
 
 .custom-label label {
     font-weight: 500;
 }
 
 .manualLeftTabIcon img {
     max-height: 27px;
 }
 
 .manualLeftTabName h6 {
     display: flex;
     align-items: center;
 }
 
 .accessment-headings h6 {
     font-weight: 600;
 }
 
 .countryDD {
     z-index: 10;
 }
 
 .StateDD {
     z-index: 9;
 }
 
 .cityDD {
     z-index: 8;
 }
 
 .rightAnsBrd {
     color: yellowgreen;
 }
 
 .wrongAnsBrd {
     color: red;
 }
 
 .bold {
     font-weight: bold;
 }
 
 .word-wrap {
     word-break: break-all;
 }
 
 .assessment-accordion .accordion-button {
     padding-right: 50px;
     box-shadow: 0 0 0 2px #bbb;
     background: white;
     color: black;
 }
 
 .assessment-accordion .accordion-button:focus {
     background: white;
     color: black;
 }
 
 .assessment-accordion .accordion-button:not(.collapsed) {
     background: white;
     color: black;
 }
 
 .accessment-headings {
     padding: 0rem 1.25rem;
     padding-right: 50px;
 }
 /* .alarm-grid .ag-theme-alpine .ag-ltr .ag-cell{
  background: none;
    box-shadow: none;
    margin-top: -6px;
}
.alarm-grid .ag-text-field-input{
  height: 10px !important;
} */
 
 .showLegend span {
     margin-right: 30px;
 }
 
 .showLegend {
     text-align: center;
 }
 .sidebarLink{
     font-weight: bolder;
     font-size: 14px;
     cursor: pointer;
 }