.organization-search-input {
    border-radius: 5px 0px 0px 5px !important;
    margin-left: 1rem !important;
    padding-left: 1rem !important;
    border: 1px solid #ddd8d8 !important;
    box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%) !important;
}

.entry-select {
    border: 1px solid #ddd8d8;
    box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%) !important;
}

.organization-search-input:focus-visible, .organization-status-select:focus-visible, .entry-select:focus-visible {
    outline: none !important;
}

.organization-search-button {
    border-radius: 0px 5px 5px 0px !important;
    background-color: #FF7538 !important;
    color: white !important;
    box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%) !important;
}

.organization-status-button {
    border-radius: 5 px 0 px 0 px 5 px !important;
    background: #FF7538 !important;
    color: white !important;
    box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%) !important;
    cursor: context-menu;
}

.organization-status-select {
    border-radius: 0px 5px 5px 0px !important;
    box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%) !important;
    border: 1px solid #ddd8d8 !important;
}

.organization-plus-select {
    background: #FF7538 !important;
    border: none !important;
    box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%) !important;
}

.font-bold {
    font-weight: bold !important;
}

.color-orange {
    color: #FF7538 !important;
}

.pagination:focus {
    box-shadow: none !important;
    background: #FF7538 !important;
    color: white !important;
}

/* .organization-list-div {
    overflow-x: auto;
    overflow-y: scroll;
    height: 52vh;
} */

/* .organization-table {
} */

/* @media screen and (min-width: 1024px) and (min-height: 1320px) { */

/* iPad Pro */

/* .organization-list-div {
        height: 40vh;
    }
} */

/* @media screen and (min-width: 1024px) and (max-height: 1310px) { */

/* Laptop */

/* .organization-list-div {
        height: 40vh;
    } */

/* } */

@media screen and (max-width: 575px) {
    .organization-search-input {
        min-width: 245px !important;
    }
}